import { Icon, IconColor } from '@volvo-cars/react-icons';
import React, { AnchorHTMLAttributes, Fragment, useState } from 'react';
import { ExtendCSS, Inline } from 'vcc-ui';

import { Click, ClickProps } from '@vcc-www/buttons';

export type InlineLinkProps = ClickProps & {
  href: string;
  children: string;
  forceNewTab?: true;
  extend?: ExtendCSS;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const InlineLink: React.FC<InlineLinkProps> = ({
  href,
  trackEventAction = 'link|click',
  trackEventLabel = href,
  forceNewTab,
  extend,
  children,
  ...props
}) => {
  const [onHover, setHover] = useState(false);

  const isExternal = openNewTab(href);
  const newTab = forceNewTab || isExternal;

  return (
    <Click
      href={href}
      target={newTab ? '_blank' : undefined}
      trackEventAction={trackEventAction}
      trackEventLabel={trackEventLabel}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      extend={[inlineLinkStyle(isExternal), extend]}
      {...props}
    >
      {newTab ? (
        <NewTab onHover={onHover} isExternal={isExternal}>
          {children}
        </NewTab>
      ) : (
        <span>{children}</span>
      )}
    </Click>
  );
};

const NewTab: React.FC<{
  onHover: boolean;
  isExternal: boolean;
  children: string;
}> = ({ onHover, isExternal: isExternalLink, children }) => {
  const words = children.split(' ').filter((word) => word !== '');

  return (
    <>
      {words.map((word, index) => (
        <Fragment key={index}>
          {words.length - 1 === index ? (
            <Inline extend={{ display: 'inline-flex' }}>
              {word}
              <Inline extend={iconStyle}>
                <Icon
                  icon="external-link"
                  size={16}
                  color={onHover ? 'primary' : getColor(isExternalLink)}
                />
              </Inline>
            </Inline>
          ) : (
            <span>{word} </span>
          )}
        </Fragment>
      ))}
    </>
  );
};

const openNewTab = (href: string) => href.startsWith('http');

const getColor = (isExternalLink: boolean): IconColor =>
  isExternalLink ? 'accent-blue' : 'secondary';

export const inlineLinkStyle = (
  isExternalLink: boolean = false,
): ExtendCSS => ({
  display: 'inline',
  '& span': {
    transition: 'color 150ms ease',
    color: `foreground.${getColor(isExternalLink)}`,
    textDecoration: isExternalLink ? 'none' : 'underline',
  },
  '&:hover span': {
    color: 'foreground.primary',
    textDecoration: 'none',
  },
});

const iconStyle: ExtendCSS = ({ theme: { baselineSubGrid } }) => ({
  margin: `0 ${baselineSubGrid}px`,
  display: 'flex',
  alignItems: 'center',
});

export default InlineLink;
