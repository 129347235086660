import { ResponsiveImage } from '@vcc-package/media';

import { Link, LinkProps as VCCLinkProps } from '@vcc-www/buttons';

import styles from './Promotion.module.css';

type LinkProps = Omit<VCCLinkProps, 'children' | 'href'> & {
  text: string;
  href: string;
};

type PromotionProps = {
  text: string;
  image: { src: string; alt: string };
  link: LinkProps;
};

const Promotion: React.FC<PromotionProps> = ({ image, text, link }) => {
  const smImage = {
    src: image.src,
    alt: image.alt,
    width: 150,
    height: 95,
  };

  return (
    <div className={styles.promotionWrapper}>
      <ResponsiveImage
        className="object-contain"
        images={{ sm: smImage }}
        alt={image.alt}
        loading="lazy"
      />
      <div style={{ margin: 'auto' }}>
        <p className="text-secondary">{text}</p>
        <div style={{ width: 'fit-content' }}>
          <Link className={styles.link} {...link}>
            {link.text}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Promotion;
