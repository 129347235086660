import ImageCard from 'molecules/Card/ImageCard';
import {
  HeadingElement,
  LinkProps,
  ImageCardProps as OriginalImageCardProps,
} from 'molecules/Card/types';

import styles from './ImageCardGrid.module.css';

export type ImageCardProps = Pick<
  OriginalImageCardProps,
  'link' | 'text' | 'title' | 'image'
> & {
  link: LinkProps;
};

export type ImageCardGridProps = {
  headingAs?: HeadingElement;
  imageCards: ImageCardProps[];
};

const ImageCardGrid: React.FC<ImageCardGridProps> = ({
  headingAs,
  imageCards,
}) => (
  <div className={styles.wrapper}>
    {imageCards.map((props: ImageCardProps, index: number) => (
      <ImageCard headingAs={headingAs} key={index} {...props} />
    ))}
  </div>
);

export default ImageCardGrid;
