import styles from './LogosDisplay.module.css';

export type Logo = {
  src: string;
  alt: string;
  topOffset?: number;
};

const LogosDisplay: React.FC<{
  logos: Logo[];
  logoSize?: { height: number; width: number };
}> = ({ logos, logoSize }) => (
  <div className={styles.logosDisplay}>
    {logos.map((logo, i) => (
      <div
        className="flex justify-center items-center"
        style={logo.topOffset ? { paddingTop: logo.topOffset } : {}}
        key={i}
      >
        <img
          loading="lazy"
          src={logo.src}
          alt={logo.alt}
          {...(logoSize && { ...logoSize })}
        />
      </div>
    ))}
  </div>
);

export default LogosDisplay;
