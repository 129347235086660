import { cssJoin } from '@volvo-cars/css/utils';
import { EmailInput } from '@volvo-cars/react-forms';
import { FocusEvent, FormEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { logException } from '@collab/utils/logger';
import { isValidEmail, simpleEmailRegex } from '@vcc-www/utils/validation';

import styles from './Newsletter.module.css';

export type SubscribeMethod = (email: string) => Promise<boolean>;

const SubscribeForm: React.FC<{
  subscribe: SubscribeMethod;
}> = ({ subscribe }) => {
  const { register, handleSubmit, reset } = useFormContext();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [sentSuccess, setSentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (sentSuccess) {
      setSentSuccess(false);
    }

    const value = e.target.value;

    if (value && !isValidEmail(value)) {
      setErrorMessage(subscribeInvalidEmailMsg);
    } else if (errorMessage) {
      setErrorMessage(undefined);
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleSubmit(async (data) => {
      setIsLoading(true);

      try {
        const success = await subscribe(data.email);

        if (success) {
          setSentSuccess(true);
          reset({ email: '' });
        } else {
          setErrorMessage(subscribeInvalidEmailMsg);
        }
      } catch (e) {
        setErrorMessage(subscribeErrorMsg);
        logException(e);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  return (
    <form
      className={cssJoin(
        'flex-col md:flex-row justify-center gap-16 md:gap-24 self-center',
        styles.form,
      )}
      onSubmit={onSubmit}
    >
      <div className={styles.input}>
        <EmailInput
          {...register('email', {
            pattern: simpleEmailRegex,
            onBlur,
          })}
          label="Email"
          required
          maxLength={320}
          disabled={isLoading}
          errorMessage={errorMessage as any}
          hint={sentSuccess && subscribeSuccessMsg}
        />
      </div>
      <button
        type="submit"
        aria-disabled={isLoading}
        className="button-filled md:my-4"
        aria-label="send newsletter signup"
      >
        Send
      </button>
    </form>
  );
};

const subscribeErrorMsg =
  'Something went wrong. We could not add you to the mailing list right now. Please try again later.';
const subscribeSuccessMsg = 'Welcome to our mailing list.';
const subscribeInvalidEmailMsg = 'Invalid email address.';

export default SubscribeForm;
