import { backendApiBaseUrl, UnhandledStatus } from 'libs/api';

export const subscribe = async (email: string): Promise<boolean> => {
  const response = await fetch(`${backendApiBaseUrl}/newsletter/subscribe`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ emailAddress: email }),
  });

  if (response.status === 400) {
    return false;
  }

  if (response.status !== 204) {
    throw await UnhandledStatus.fromResponse(response);
  }

  return true;
};
