import { Image } from '@collab/atoms';

import Card from './Card';
import styles from './Cards.module.css';
import { ImageCardProps } from './types';

const ImageCard: React.FC<ImageCardProps> = ({
  image,
  title,
  headingAs,
  text,
  link,
}) => {
  const imageProps = {
    aspectRatio: image.aspectRatio || [4, 3],
    ...image,
    className: styles.resetMargins,
  };
  return (
    <Card
      link={link}
      text={text}
      headingAs={headingAs}
      title={title}
      withTapArea
    >
      <Image {...imageProps} />
      <div className="mt-32" />
    </Card>
  );
};

export default ImageCard;
