import { FormProvider, useForm } from 'react-hook-form';

import { InlineLink } from '@collab/atoms';

import SubscribeForm, { SubscribeMethod } from './SubscribeForm';

type InfoNotice = {
  text: string;
  href: string;
  linkText: string;
};

export type NewsletterProps = {
  title: string;
  subheading: string;
  text: {
    firstRow: string;
    secondRow?: string;
  };
  infoNotice?: InfoNotice;
  subscribe: SubscribeMethod;
};

const Newsletter: React.FC<NewsletterProps> = ({
  title,
  subheading,
  text,
  infoNotice,
  subscribe,
}) => {
  const methods = useForm({ mode: 'onBlur', defaultValues: { email: '' } });

  return (
    <div className="flex-col text-center">
      <h2 className="statement-3">{title}</h2>
      <h3 className="heading-2 mt-24 mb-32 md:mb-48">{subheading}</h3>
      <FormProvider {...methods}>
        <SubscribeForm subscribe={subscribe} />
      </FormProvider>
      <p className="mt-32 md:mt-16 text-secondary">{text.firstRow}</p>
      {text.secondRow && <p className="text-secondary">{text.secondRow}</p>}
      {infoNotice && (
        <small className="micro text-secondary mt-16">
          {`${infoNotice.text} `}
          <InlineLink href={infoNotice.href} target="_blank">
            {infoNotice.linkText}
          </InlineLink>
          .
        </small>
      )}
    </div>
  );
};

export default Newsletter;
