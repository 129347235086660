export const simpleEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * A very basic sanity check.
 * To validate a email correctly you need to send a email and
 * let the user verify that they received it.
 *
 * Code taken from here: https://ui.dev/validate-email-address-javascript/
 */
export const isValidEmail = (email: string | undefined): boolean => {
  return !!email && simpleEmailRegex.test(email);
};
