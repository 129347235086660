import { Track } from '@volvo-cars/tracking';
import { Block, Spacer } from 'vcc-ui';

import { ContentWrapper, H2, H3, Link, Paragraph } from '@collab/atoms';
import { Newsletter, SubscribeMethod } from '@collab/features/newsletter';
import {
  Callout,
  HeroImage,
  PageTitle,
  TextStatement,
} from '@collab/molecules';

import RestrictedVolvoUserContent from 'atoms/RestrictedVolvoUserContent';
import { subscribe } from 'libs/newsletter.api';
import LoginStatusCallout from 'molecules/LoginStatusCallout';
import LogosDisplay from 'molecules/LogosDisplay';
import Markdown from 'molecules/Markdown';
import CardGrid from 'organisms/CardGrid';
import ImageCardGrid from 'organisms/ImageCardGrid';
import Promotion from 'organisms/Promotion';
import { PageProps } from 'types/pages';

const Home: React.FC<PageProps> = ({ content, pageMeta }) => {
  const subscribeToNewsletter: SubscribeMethod = async (email) =>
    subscribe(email);

  return (
    <>
      <ContentWrapper>
        <PageTitle title={pageMeta.title} category={pageMeta.category} />
      </ContentWrapper>
      <HeroImage {...content.heroImage} />
      <ContentWrapper>
        <TextStatement {...content.textStatement} />
        <H2>{content.gettingStarted.heading}</H2>
        <Markdown>{content.gettingStarted.body}</Markdown>
        <Spacer size={[3, 4]} />
        <ImageCardGrid headingAs="h3" imageCards={content.tools} />
        <LoginStatusCallout statusTexts={content.loginStatusCallout} />
        <RestrictedVolvoUserContent>
          <H3>{content.restrictedToolsTitle}</H3>
          <Markdown>{content.restrictedToolsText}</Markdown>
          <Spacer size={[4, 5]} />
          <CardGrid cards={content.restrictedTools} />
        </RestrictedVolvoUserContent>
        <H2>{content.coCreations.heading}</H2>
        <Markdown>{content.coCreations.body}</Markdown>
        <Spacer size={[3, 4]} />
        <Promotion
          {...content.coCreations.promotion}
          link={{
            ...content.coCreations.promotion.link,
            trackEventAction: 'link|click',
            trackEventLabel: content.coCreations.promotion.link.href,
          }}
        />
        <Spacer size={[3, 4]} />
        <LogosDisplay
          logos={content.coCreations.logos}
          logoSize={{ height: 50, width: 120 }}
        />
        <H2>{content.careers.title}</H2>
        <Markdown>{content.careers.body}</Markdown>
        <Spacer size={[3, 4]} />
        <Callout
          title={content.careers.callout.title}
          content={<Paragraph>{content.careers.callout.content}</Paragraph>}
          button={
            <Track
              eventAction="link|click"
              eventLabel={content.careers.callout.button.href}
            >
              <Link
                href={content.careers.callout.button.href}
                className="button-outlined"
                aria-label="Explore careers"
              >
                {content.careers.callout.button.buttonText}
              </Link>
            </Track>
          }
        />
        <Spacer size={[12, 16]} />
        <aside className="flex-col">
          <Block id="newsletter" />
          <Newsletter
            subscribe={subscribeToNewsletter}
            {...content.subscribeToNewsletter}
          />
        </aside>
      </ContentWrapper>
    </>
  );
};

export default Home;
